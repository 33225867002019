import React, { createContext, useMemo } from "react";

import { type FirebaseOptions, initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// Define the shape of your context value
export interface FirebaseContextValue {
  logEvent?: (event: string, data: Record<string, unknown>) => void;
  setContext?: (context: Record<string, unknown>) => void;
}

// Create the context
export const FirebaseContext = createContext<FirebaseContextValue | undefined>(
  undefined
);

interface Props {
  config: FirebaseOptions;
  children: React.ReactNode;
}

// Create a provider component
export const FirebaseProvider = ({ children, config }: Props) => {
  const app = config.apiKey ? initializeApp(config) : undefined;
  const [context, setContext] = React.useState<Record<string, unknown>>({});
  const analytics = app && getAnalytics(app);

  const value: FirebaseContextValue = useMemo(
    () => ({
      logEvent: (event: string, data: Record<string, unknown>) => {
        if (analytics) {
          logEvent(analytics, event, { ...context, data });
        } else {
          console.debug("[Firebase] logEvent", event, data);
        }
      },
      setContext,
    }),
    [analytics, context]
  );

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
