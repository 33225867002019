import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  CSSProperties,
  useRef,
} from "react";
import DocumentMeta from "react-document-meta";
import { useFirebase } from "@shared/firebase";

import {
  DEFAULT_THEME,
  type GameDataInterface,
  type GameEventType,
} from "@shared/game-engine";
import { type GameDefinitionDto, PlayerApiClient } from "@shared/api-client";
import {
  GamePlayerOffline,
  RequireInteractionBoundary,
  FullScreenBoundary,
} from "@shared/game-player";
import { deobfuscate } from "@shared/utils/obfuscator";
import UfoPildImg from "@shared/branding/theufolab/ufo-pild.png";
import RocketGif from "@shared/branding/theufolab/rocket.gif";
import { RenderingContext } from "../../../shared/game-player/src/types/canvas";

// Irrelevant events for analytics
const SKIP_EVENTS: (keyof GameEventType)[] = [
  "gameStateUpdated",
  "gameLogUpdated",
  "gameActionEnqueued",
];

const UFOPILD_STYLES: CSSProperties = {
  position: "absolute",
  bottom: 5,
  left: 5,
  zIndex: 1000,
  opacity: 0.5,
  height: "3vh",
  minHeight: "30px",
};

const apiClient = new PlayerApiClient(import.meta.env.VITE_BACKEND_BASEURL);

function UfoPild() {
  return (
    <a href="https://theufolab.com" target="_blank">
      <img
        src={UfoPildImg}
        alt="Powered by UFOLab"
        title="Powered by UFOLab"
        style={UFOPILD_STYLES}
      />
    </a>
  );
}

function Player() {
  const { logEvent } = useFirebase();
  const [uuid, setUuid] = useState<string>();
  const [error, setError] = useState<Error | null>();
  const [game, setGame] = useState<GameDefinitionDto | null>();
  const [gameData, setGameData] = useState<GameDataInterface | null>();

  const viewportRef = useRef<HTMLDivElement>(null);
  const [ready, setReady] = useState(false);
  const [renderingContext, setRenderingContext] =
    useState<Partial<RenderingContext>>();

  const loadGame = useCallback(async (uuid: string) => {
    apiClient
      .get({ path: "/g/:uuid", params: { uuid } })
      .then(async (game) => {
        setGame(game);

        const gameData = await apiClient.get({
          path: "/g/:uuid/data",
          params: { uuid },
        });

        const parsedGameData = JSON.parse(
          deobfuscate(gameData.d, uuid)
        ) as GameDataInterface;

        setGameData(parsedGameData);

        // Apply theme
        // TODO: Use a component
        document.body.style.backgroundColor =
          parsedGameData.theme?.background ||
          DEFAULT_THEME.background ||
          "#FFFFFF";

        document.body.style.color =
          parsedGameData.theme?.text || DEFAULT_THEME.text || "#000000";
      })
      .catch(setError);
  }, []);

  /* Handle window resize */
  useEffect(() => {
    if (!gameData?.screen || !viewportRef.current) return;

    const updateScale = () => {
      if (!gameData?.screen || !viewportRef.current) return;

      const bounds = viewportRef.current.getBoundingClientRect();
      const scale = Math.min(
        bounds.height / gameData.screen.height,
        bounds.width / gameData.screen.width
      );

      setRenderingContext({
        viewportWidth: bounds.width,
        viewportHeight: bounds.height,
        canvasScale: scale,
        canvasX: bounds.width / 2 - (gameData.screen.width * scale) / 2,
        canvasY: bounds.height / 2 - (gameData.screen.height * scale) / 2,
      });
    };

    updateScale();

    // Observe the viewport for changes
    const observer = new ResizeObserver(updateScale);
    observer.observe(viewportRef.current);

    return () => {
      observer.disconnect();
    };
  }, [gameData?.screen, ready]);

  // Initial load
  useEffect(() => {
    const uuid = window.location.pathname.split("/")[1];
    setUuid(uuid);
  }, []);

  // Game load
  useEffect(() => {
    if (uuid) {
      loadGame(uuid);
    } else {
      // setError(new Error("Game not found"));
    }
  }, [uuid, loadGame]);

  const onEventHandler = useCallback(
    (event: string, data: Record<string, unknown>) => {
      if (SKIP_EVENTS.includes(event as keyof GameEventType)) {
        return;
      }

      const filteredData = Object.keys(data).reduce<Record<string, unknown>>(
        (acc, key) => {
          // Filter out non-serializable data
          if (["string", "number", "boolean"].includes(typeof data[key])) {
            return { ...acc, [key]: data[key] };
          }
          return acc;
        },
        {}
      );

      logEvent?.(event, { game_uuid: uuid, ...filteredData });
    },
    [logEvent, uuid]
  );

  const playerInnerStyles = useMemo(
    () => ({
      width: gameData?.screen?.width,
      height: gameData?.screen?.height,
      transform: `translate(-50%, -50%) scale(${renderingContext?.canvasScale ?? 1})`,
    }),
    [
      gameData?.screen?.width,
      gameData?.screen?.height,
      renderingContext?.canvasScale,
    ]
  );

  const playerSettings = useMemo(
    () => ({
      assetBaseUrl: game?.assetBaseUrl || "/assets",
      libraryBaseUrl: import.meta.env.VITE_LIBRARY_BASE_URL || "/library",
      pluginBaseUrl: import.meta.env.VITE_PLUGIN_BASE_URL,
      builtinPluginBaseUrl: import.meta.env.VITE_PLUGIN_BUILTIN_BASE_URL,
      pluginIndexFile: import.meta.env.VITE_PLUGIN_INDEX_FILE,
    }),
    [game]
  );

  if (error) {
    throw error;
  }

  const Loading = useMemo(() => {
    return (
      <div className={`ufo-page-loading ${gameData ? "out" : ""}`}>
        <img src={RocketGif} alt="Loading..." />
      </div>
    );
  }, [gameData]);

  const docMeta = useMemo(
    () => ({
      "application-name": `UFOLab ${game?.name ? `| ${game.name}` : ""}`,
    }),
    [game]
  );

  if (!game || !gameData) {
    return Loading;
  }

  return (
    <DocumentMeta
      title={docMeta["application-name"]}
      description={game.description}
      meta={docMeta}
    >
      {Loading}
      <FullScreenBoundary
        requireFullScreen={gameData.screen?.fullScreen}
        requireOrientation={gameData.screen?.orientation}
        fullScreenWarning={(doFullScreen) => (
          <div className="ufo-full-screen-warning" onClick={doFullScreen}>
            <p>Click to enter fullscreen</p>
            <div className="icon" />
          </div>
        )}
        orientationWarning={
          <div className="ufo-full-screen-warning">
            <p>Please rotate your device</p>
            <div className="icon" />
          </div>
        }
      >
        <div className="ufo-game-player" draggable={false} ref={viewportRef}>
          <div className="ufo-game-player-inner" style={playerInnerStyles}>
            <RequireInteractionBoundary
              enabled={false} // Disable the warning for now
              warning={(play) => (
                <div
                  className="ufo-full-screen-warning"
                  onClick={() => {
                    setReady(true);
                    play();
                  }}
                >
                  Click to start
                </div>
              )}
            >
              <GamePlayerOffline
                gameData={gameData}
                settings={playerSettings}
                onEvent={onEventHandler}
                renderingContext={renderingContext}
              />
            </RequireInteractionBoundary>
          </div>
        </div>
      </FullScreenBoundary>
      <UfoPild />
    </DocumentMeta>
  );
}

export default Player;
