import { useContext } from "react";
import { FirebaseContext, type FirebaseContextValue } from "./FirebaseContext";

// Create a custom hook to access the context
export const useFirebase = (): FirebaseContextValue => {
  const context = useContext(FirebaseContext);

  if (!context) {
    throw new Error("useFirebase must be used within a FirebaseProvider");
  }

  return context;
};
